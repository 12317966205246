<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-textarea
          v-model="code"
          :label="$t('labels.tracking')"
          placeholder="Tracking1;Tracking2;Tracking3..."
          dense
          outlined
          hide-details
          class="c-input-small"
          rows="6"
          @paste.prevent.stop="onCodePaste"
        ></v-textarea>
        <v-btn
          color="success"
          class="mt-3"
          large
          block
          :disabled="!code"
          @click="generateStamp"
        >
          <v-icon>mdi-printer</v-icon> In
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <v-alert text prominent color="teal" icon="mdi-check" border="left">
          In thành công:
          <template v-if="stampLink">
            <b>{{ items.length }}</b
            >/{{ trackingIds.length }} đơn
          </template>
        </v-alert>
        <v-alert
          text
          prominent
          type="error"
          icon="mdi-alert-circle-outline"
          border="left"
        >
          Chưa có tem:
          <template v-if="stampLink">
            <OrderTracking
              v-for="tracking_id in noStampTrackings"
              :key="tracking_id"
              :tracking-id="tracking_id"
              class="mr-2"
            />
          </template>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "MergeStamp",
  components: {
    OrderTracking: () => import("@/components/common/OrderTracking"),
  },
  data: () => ({
    isLoading: false,
    code: "",
    items: [],
    filename: null,
  }),
  computed: {
    stampLink() {
      if (!this.filename) {
        return null;
      }
      return `${process.env.VUE_APP_FILE_CDN_URL}/stamp_order_merge/${this.filename}`;
    },
    trackingIds() {
      if (!this.code) {
        return [];
      }
      return this.code.split(";");
    },
    noStampTrackings() {
      return [...this.trackingIds].filter(
        (tracking_id) => !this.items.includes(tracking_id)
      );
    },
  },
  methods: {
    onCodePaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const codeSplit = value.split("\n").filter((v) => v);
        this.code = codeSplit.join(";");
      } else if (value.includes(" ")) {
        const codeSplit = value.split(" ").filter((v) => v);
        this.code = codeSplit.join(";");
      } else {
        this.code += value;
      }
    },
    async generateStamp() {
      this.filename = null;
      if (this.isLoading) {
        this.code = "";
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/common/v1/generate-stamp-before-merge",
          {
            tracking_ids: this.trackingIds,
          }
        );
        this.items = [...data];
        return this.mergeStamp();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.code = "";
        this.$root.$emit("playErrorAudio");
      }
    },
    async mergeStamp() {
      try {
        const { data } = await httpClient.post(
          "/common/v1/generate-stamp-merge",
          {
            tracking_ids: this.items,
          }
        );
        this.filename = data;
        this.isLoading = false;
        //this.code = ''
        window.open(this.stampLink, "_blank");
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.code = "";
        this.$root.$emit("playErrorAudio");
      }
    },
  },
};
</script>
